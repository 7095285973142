/**
 * This script is not for production use
 * It contains just demo scripts for templates
 */

/**
 * Search form
 */
$(function() {
    $('.search__input').on('input', function(event) {
        const input = $(this)

        if (input.val().length > 0) {
            $('#searchResults-history').addClass('d-none')
            $('#searchResults-search').removeClass('d-none')

            if (input.val().length > 5) {
                $('#searchResults-noResults').removeClass('d-none')
                $('#searchResults-searchList').addClass('d-none')
            } else {
                $('#searchResults-noResults').addClass('d-none')
                $('#searchResults-searchList').removeClass('d-none')
            }
        } else {
            $('#searchResults-history').removeClass('d-none')
            $('#searchResults-search').addClass('d-none')
        }
    })
})